import React from "react";
import ReactDOM from "react-dom";
import GlobalStyle from "./utils/style/GlobalStyle";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Header from "./components/Header";
import Error from "./components/Error";
import Footer from "./components/Footer";
import Games from "./pages/Games";
import Menu from "./pages/Menu";
import Book from "./pages/Book";
import Confidentiality from "./pages/Confidentiality";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <GlobalStyle />
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/#contact" />
        <Route path="/#reservation" />
        <Route path="/nos-jeux" element={<Games />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/reservation" element={<Book />} />
        <Route path="/confidentiality" element={<Confidentiality />} />
        <Route path="*" element={<Error />} />
      </Routes>
      <Footer />
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

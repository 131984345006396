import styled from "styled-components";
import Category from "../../components/Category";
import { useEffect, useState } from "react";

const MenuContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitleH1 = styled.h1`
  font-size: 3rem;
  font-weight: 700;
  font-family: Lato;
  text-align: center;
  color: #fff;
  margin: 75px 0;
`;

const PageContentContainer = styled.div`
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
  @media (min-width: 768px) {
    border-radius: 10px 10px 0 0;
    width: 95%;
  }
`;

function Menu() {
  const [data, setData] = useState([]);

  // Fetch the data of all products when the page is loaded and populate it with product_category
  useEffect(() => {
    fetch("https://les-arpenteurs.herokuapp.com/api/product-categories")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setData(data.data);
      });
  }, []);

  return (
    <MenuContainer>
      <TitleH1>Notre carte</TitleH1>
      <PageContentContainer>
        {data.map((category) => (
          <Category
            key={category.id}
            id={category.id}
            name={category.attributes.name}
            icon={category.attributes.icon}
          />
        ))}
      </PageContentContainer>
    </MenuContainer>
  );
}

export default Menu;

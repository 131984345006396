import styled from "styled-components";
import colors from "../../utils/style/colors";
import { emailTemplate } from "../../constants/emailTemplate";

const BookContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitleH1 = styled.h1`
  font-size: 3rem;
  font-weight: 700;
  font-family: Lato;
  text-align: center;
  color: #fff;
  margin: 75px 0;
`;

const PageContentContainer = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
  @media (min-width: 768px) {
    border-radius: 10px;
    padding: 50px;
    width: 95%;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitleH2 = styled.h2`
  font-size: 1.2rem;
  font-weight: 500;
  font-family: Lato;
  text-align: center;
  color: #3c4858;
  margin-bottom: 20px;
`;

const Paragraph = styled.p`
  font-family: Lato;
  font-weight: 300;
  font-size: 18px;
  color: ${colors.paragraph};
  text-align: center;
  margin-bottom: 20px;
  line-height: 1.5rem;
  span {
    font-weight: 600;
  }
  @media (min-width: 768px) {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
`;

const MailToButton = styled.a`
  background-color: ${colors.primary};
  text-align: center;
  text-decoration: none;
  padding: 1em 2em;
  color: #fff;
  border-radius: 30px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.8rem;
  margin: 50px auto 0 auto;
  display: flex;
  justify-content: center;
  width: fit-content;
`;

function Book() {
  return (
    <BookContainer>
      <TitleH1>Réserver pour un groupe</TitleH1>
      <PageContentContainer>
        <TitleH2>Dites-nous en plus</TitleH2>
        <Paragraph>
          Vous souhaitez organiser une soirée dans un bar entièrement privatisé
          pour une occasion bien spécifique ? Un team building ? Un colloque
          annuel ? Une réunion d'anciens étudiants ? Privatisez notre
          établissement uniquement pour vous, jusqu'à 65 personnes.
        </Paragraph>
        <Paragraph>
          Pour réserver pour un groupe, veuillez remplir le formulaire
          ci-dessous et nous vous contacterons dans les plus brefs délais.
        </Paragraph>
        <Paragraph
          style={{
            backgroundColor: colors.green,
            padding: 16,
            borderRadius: 8,
            color: "#fff",
            maxWidth: 400,
          }}
        >
          Notez que nous ne prenons pas de réservation pour les groupes de moins
          de 20 personnes.
        </Paragraph>
        <MailToButton
          href={`mailto:contact@lesarpenteurs-bar.com?subject=Demande de réservation&body=${emailTemplate}`}
          onClick={() => {
            window.gtag("event", "conversion", {
              send_to: "AW-759456826/4X4zCNHozawZELrIkeoC",
              cta: "reservation/button",
            });
          }}
        >
          Demander un devis par mail
        </MailToButton>
      </PageContentContainer>
    </BookContainer>
  );
}

export default Book;

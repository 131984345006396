import {
  faBeer,
  faHamburger,
  faCoffee,
  faWineGlass,
  faWineGlassAlt,
  faWineBottle,
  faGlassWhiskey,
  faCocktail,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import colors from "../../utils/style/colors";
import Product from "../Product";
import { useState, useEffect } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";

const CategoryContainer = styled.div`
  width: 95%;
  background-color: #fffaf1;
  margin: 20px 0;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 10px;
  position: relative;
`;

const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  background-color: ${colors.primary};
  border-radius: 50%;
  font-size: 30px;
  padding: 10px;
`;

const CategoryTitle = styled.h1`
  text-align: center;
  text-transform: uppercase;
  color: ${colors.primary};
  letter-spacing: 0.3em;
  position: relative;
  font-size: 1.4rem;
  margin-top: 20px;
  font-weight: 500;
`;

const OrangeDivider = styled.div`
  width: 100px;
  height: 2px;
  background-color: ${colors.primary};
  margin: 10px auto 20px auto;
`;

const ProductsContainer = styled.div`
  @media (min-width: 900px) {
    display: flex;
    flex-wrap: wrap;
  }
`;

function Category({ id, name, icon }) {
  const [products, setProducts] = useState([]);

  library.add(
    faBeer,
    faHamburger,
    faCoffee,
    faWineGlass,
    faWineGlassAlt,
    faWineBottle,
    faGlassWhiskey,
    faCocktail
  );

  // Fetch the data of all products when the page is loaded and populate it with product_category
  useEffect(() => {
    fetch(
      "https://les-arpenteurs.herokuapp.com/api/products?populate=product_category&sort=createdAt"
    )
      .then((res) => {
        return res.json();
      })
      .then((products) => {
        setProducts(products.data);
      });
  }, []);

  return (
    <CategoryContainer>
      <Icon
        icon={["fas", `${icon}`]}
        color="#fff"
        style={{ width: "30px", height: "30px" }}
      />
      <CategoryTitle>{name}</CategoryTitle>
      <OrangeDivider></OrangeDivider>
      <ProductsContainer>
        {products
          .filter((product) => {
            if (product.attributes.product_category.data.id === id) {
              return product;
            }
            return false;
          })
          .map((product) => (
            <Product
              key={product.id}
              name={product.attributes.name}
              description={product.attributes.description}
              price={product.attributes.price}
              isBio={product.attributes.isBio}
              productCategory={product.attributes.product_category.data.id}
            />
          ))}
      </ProductsContainer>
    </CategoryContainer>
  );
}

export default Category;

import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers, faSignal, faClock, faBirthdayCake, faHandshake } from "@fortawesome/free-solid-svg-icons";

const CardContainer = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    border-radius: 10px;
    border: solid 1px #eee;
    -webkit-box-shadow: 4px 4px 5px rgba(0,0,0,0.2);
    box-shadow: 4px 4px 5px rgba(0,0,0,0.2);
    margin-top: 30px;
    transition: all ease-in-out 200ms;
`

const CardImg = styled.img`
    width: 100%;
    max-height: 300px;
    min-height: 250px;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
`

const CardBody = styled.p`
    padding: 15px;
    font-size: 0.9rem;
    font-weight: 300;
`

const CardLabel = styled.div`
    background-color: ${props => props.players ? "#5cb85c" : props.difficulty ? "orange" : props.time ? "cyan" : props.age ? "violet" : "chartreuse"};
    color: #fff;
    box-shadow: 0 1px rgba(0,0,0,0.2);
    -moz-box-shadow: 0 1px rgba(0,0,0,0.2);
    -webkit-box-shadow: 0 1px rgba(0,0,0,0.2);
    text-shadow: 1px 1px #333;
    position: absolute;
    right: -10px;
    top: ${props => props.players ? "15px" : props.difficulty ? "55px" : props.time ? "95px" : props.age ? "135px" : "175px"};
    padding: 5px 10px;
    font-size: 1.2rem;
    border-radius: 3px;
    &:before {
        content: "";
        border: 4px solid transparent;
        border-bottom: 4px solid ${props => props.players ? "#4a934a" : props.difficulty ? "orange" : props.time ? "cyan" : props.age ? "violet" : "chartreuse"};
        border-left: 4px solid ${props => props.players ? "#4a934a" : props.difficulty ? "orange" : props.time ? "cyan" : props.age ? "violet" : "chartreuse"};
        position: absolute;
        right: 0px;
        top: -7px;
    }
`

const CardTitle = styled.div`
    font-size: 1rem;
    text-transform: capitalize;
    color: #fff;
    padding: 5px 15px;
    position: absolute;
    top: 260px;
    left: -10px;
    background: orange;
    border-radius: 3px;
    text-shadow: 1px 1px #333;
    &:after {
        content: "";
        border: 4px solid transparent;
        border-bottom: 4px solid orange;
        border-right: 4px solid orange;
        position: absolute;
        left: 0px;
        top: -7px;
    }
    @media (min-width: 768px) {
        font-size: 1.25rem;
      }
`

const Icon = styled(FontAwesomeIcon)`
    drop-shadow: 1px 1px #333;
    display: inline-block;
`

function Card({name, description, playerMin, playerMax, difficulty, durationMin, durationMax, age, isCoop, picture}) {
    const labelCoop = isCoop ? (<CardLabel coop><Icon icon={faHandshake} /> Coop</CardLabel>) : '';
    let players = '';
    if (playerMin === playerMax) {
        players = playerMin;
    } else {
        players = `${playerMin} à ${playerMax}`
    }
    let duration = '';
    if (durationMin === durationMax) {
        duration = `${durationMin} min`;
    } else {
        duration = `${durationMin} à ${durationMax} min`
    }
    return(
        <CardContainer>
            <CardImg src={`${picture}`} />
            <CardBody>
                {description}
            </CardBody>
            <CardLabel players><Icon icon={faUsers} /> {players}</CardLabel>
            <CardLabel difficulty><Icon icon={faSignal} /> {difficulty}</CardLabel>
            <CardLabel time><Icon icon={faClock} /> {duration}</CardLabel>
            <CardLabel age><Icon icon={faBirthdayCake} /> +{age} ans</CardLabel>
            {labelCoop}
            <CardTitle>{name}</CardTitle>
        </CardContainer>
    )
}

export default Card
import styled from "styled-components";
import colors from "../../utils/style/colors";

const BookContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitleH1 = styled.h1`
  font-size: 3rem;
  font-weight: 700;
  font-family: Lato;
  text-align: center;
  color: #fff;
  margin: 75px 0;
`;

const PageContentContainer = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
  @media (min-width: 768px) {
    border-radius: 10px;
    padding: 50px;
    width: 95%;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Paragraph = styled.p`
  font-family: Lato;
  font-weight: 300;
  font-size: 18px;
  color: ${colors.paragraph};
  text-align: center;
  margin-bottom: 20px;
  line-height: 1.5rem;
  span {
    font-weight: 600;
  }
  @media (min-width: 768px) {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
`;

function Confidentiality() {
  return (
    <BookContainer>
      <TitleH1>
        Règles de Confidentialité pour les Demandes de Devis par E-mail
      </TitleH1>
      <PageContentContainer>
        <Paragraph>
          1. Collecte des Informations : Nous collectons les informations
          fournies volontairement par les clients lorsqu'ils demandent un devis
          par e-mail. Ces informations peuvent inclure le nom, l'adresse e-mail,
          le numéro de téléphone et d'autres détails pertinents pour la demande
          de devis.
        </Paragraph>
        <Paragraph>
          2. Utilisation des Informations : Les informations collectées ne
          seront utilisées que dans le but de répondre à la demande de devis du
          client. Elles peuvent également être utilisées pour contacter le
          client afin de clarifier la demande ou de fournir des informations
          supplémentaires concernant les services demandés.
        </Paragraph>
        <Paragraph>
          3. Confidentialité : Nous nous engageons à protéger la confidentialité
          des informations personnelles des clients. Ces informations ne seront
          pas partagées, louées ou vendues à des tiers, sauf dans les cas où
          cela est nécessaire pour répondre à la demande de devis du client ou
          si requis par la loi.
        </Paragraph>{" "}
        <Paragraph>
          4. Sécurité des Données : Nous mettons en place des mesures de
          sécurité appropriées pour protéger les informations contre tout accès
          non autorisé, altération, divulgation ou destruction.
        </Paragraph>{" "}
        <Paragraph>
          5. Consentement : En soumettant une demande de devis par e-mail, le
          client consent à ce que ses informations soient collectées et
          utilisées conformément à cette politique de confidentialité.
        </Paragraph>{" "}
        <Paragraph>
          6. Droits des Clients : Les clients ont le droit de demander l'accès,
          la correction ou la suppression de leurs informations personnelles.
          Ils peuvent également retirer leur consentement à tout moment en nous
          contactant.
        </Paragraph>{" "}
        <Paragraph>
          7. Modifications de la Politique : Nous nous réservons le droit de
          modifier cette politique de confidentialité à tout moment. Les
          modifications seront publiées sur notre site web.
        </Paragraph>{" "}
        <Paragraph>
          8. Contact : Pour toute question concernant cette politique de
          confidentialité ou pour exercer vos droits en matière de protection
          des données, veuillez nous contacter à contact@lesarpenteurs-bar.com.
        </Paragraph>
        <Paragraph>Date d'entrée en vigueur : 03/01/2024</Paragraph>
      </PageContentContainer>
    </BookContainer>
  );
}

export default Confidentiality;

import styled from "styled-components";
import Logo from "../../assets/logo192.png";
import { Link } from "react-router-dom";
import colors from "../../utils/style/colors";
import { HashLink } from "react-router-hash-link/dist/react-router-hash-link.cjs.development";

const NavContainer = styled.nav`
  width: 100%;
  display: flex;
  margin: 0 auto;
  padding: 10px;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  z-index: 1;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
  @media (min-width: 768px) {
    padding: 10px 10% 10px 10%;
  }
`;

const HomeLogo = styled.img`
  height: 50px;
  cursor: pointer;
  transition: filter ease-in-out 200ms;
  &:hover {
    filter: opacity(0.75);
  }
`;

const LinksContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 10px;
`;

const StyledHashLink = styled(HashLink)`
  text-decoration: none;
  text-transform: uppercase;
  color: #000;
  font-weight: 200;
  margin-left: 10px;
  font-size: 0.75rem;
  transition: color ease-in-out 300ms;
  &:hover {
    color: ${colors.primary};
  }
  @media (min-width: 768px) {
    font-size: 0.85rem;
    margin-left: 30px;
  }
`;

function Header() {
  return (
    <NavContainer>
      <Link to="/" className="logo">
        <HomeLogo src={Logo} alt="" />
      </Link>
      <LinksContainer>
        <StyledHashLink to="/#home">Accueil</StyledHashLink>
        <StyledHashLink to="/#contact">Contact</StyledHashLink>
        <StyledHashLink to="/reservation">Privatisation</StyledHashLink>
        <StyledHashLink to="/nos-jeux">Nos jeux</StyledHashLink>
        <StyledHashLink to="/menu">Notre carte</StyledHashLink>
      </LinksContainer>
    </NavContainer>
  );
}

export default Header;

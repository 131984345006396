const colors = {
  primary: "#e09d1b",
  secondary: "#8e4d1d",
  paragraph: "#999",
  backgroundLight: "#F9F9FC",
  backgroundDark: "#4F4C6B",
  disabled: "#D7D7D7",
  green: "#7ABA78",
};

export default colors;

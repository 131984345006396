import styled from "styled-components";
import { useEffect, useState } from "react";
import Card from "../../components/Card";
import Masonry from "react-masonry-css";
import colors from "../../utils/style/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const GamesContainer = styled.div`
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const TitleH1 = styled.h1`
    font-size: 3rem;
    font-weight: 700;
    font-family: Lato;
    text-align: center;
    color: #fff;
    margin: 75px 0;
`

const PageContentContainer = styled.div`
    width: 100%;
    background-color: #fff;
    padding: 20px;
    box-sizing: border-box;
    @media (min-width: 768px) {
        border-radius: 10px;
        padding: 50px;
        width: 95%;
      }   
`

const Grid = styled(Masonry)`
      display: flex;
      width: auto;
      .my-masonry-grid_column:not(:first-child) {
          margin-left: 30px;
      }
      .divId {
          display:flex;
          justify-content: center;
      }
`

const FiltersContainer = styled.div`
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    select, input {
        height: 30px;
        background: none;
        border: 1px solid #ccc;
        border-radius: 5px;
        cursor: pointer;
        transition: all ease-in-out 200ms;
        text-align: center;
        width: 100%;
        margin-bottom: 10px;
        max-width: 300px;
        &:hover {
            border: 1px solid ${colors.primary};
        }
        option {
            background: #fff;
            border-radius: 0 0 5px 5px;
            &:hover {
                background-color: ${colors.primary};
            }
        }
    }
    span {
        cursor: pointer;
        background-color: ${colors.primary};
        padding: 5px;
        margin: 5px 5px 10px 5px;
        border-radius: 15px;
        color: #fff;
        text-align: center;
        padding: 5px 25px;
    }
    p {
        font-size: 1rem;
        color: #777;
    }
    ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-bottom: 10px;
        max-width: 300px;
        &:after {
            content: "";
            flex: auto;
        }
        li {
            font-size: 0.9rem;
            cursor: pointer;
            background-color: #fff;
            padding: 5px 10px;
            margin: 5px;
            border-radius: 5px;
            color: #333;
            text-align: center;
            width: fit-content;
            border: 1px solid #ccc;
            transition: all ease-in-out 200ms;
            &:hover {
                background-color: ${colors.primary};
                color: #fff;
                border: 1px solid rgba(0, 0, 0, 0);
            }
        }
        .selected {
            background-color: ${colors.primary};
            color: #fff;
        }
    }
    input {
        cursor: auto;
        outline: none;
        text-align: left;
        padding-left: 10px;
        box-sizing: border-box;
        margin-bottom: 0;
        border: none;
        &:hover {
            outline: none;
            border: none;
        }
    }
`

const InputContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding-right: 5px;
    width: 100%;
    max-width: 300px;
    box-sizing: border-box;
`

function Games() {
    // Breakpoints for the Masonry css displaying the cards
    const breakpointsColumnsObj = {
        default: 4,
        1375: 2,
        700:  1,
    };

    const [players, setPlayers] = useState('');
    const [difficulty, setDifficulty] = useState('');
    const [duration, setDuration] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [data, setData] = useState([]);

    function resetFilters() {
        let itemClicked = document.querySelectorAll('#nbPlayers li');
        itemClicked.forEach(item => {
            item.classList.remove('selected');
        });
        setPlayers('');
        setDifficulty('');
        const selectDifficulty = document.getElementById('difficultySelect');
        selectDifficulty.value = '';
        setSearchTerm('');
        const searchBar = document.getElementById('searchInput');
        searchBar.value = '';
        setDuration('');
        const selectDuration = document.getElementById('durationSelect');
        selectDuration.value = '';
    }

    // Fetch the data of all boardgames when the page is loaded and populate it with pictures
    useEffect(() => {
        fetch('https://les-arpenteurs.herokuapp.com/api/games?populate=picture')
        .then((res) => {
            return res.json();
        })
        .then((data) => {
            setData(data.data);
        });
    }, []);

    function filterPlayers(n) {
        let itemClicked = document.querySelectorAll('#nbPlayers li');
        if (players === n) {
            setPlayers('');
            itemClicked.forEach(item => {
                item.classList.remove('selected');
            });
        } else {
            itemClicked.forEach(item => {
                item.classList.remove('selected');
            });
            setPlayers(n);
            itemClicked[n-1].classList.add('selected');
        }
    }

    return(
        <GamesContainer>
            <TitleH1>Nos jeux</TitleH1>
            <PageContentContainer>
                <FiltersContainer>
                {/*----- Button to reinitialize filters -----*/}
                <span onClick={() => resetFilters()}>Réinitialiser les filtres</span>
                {/*----- End of button to reinitialize filters -----*/}

                {/*---- Select the duration -----*/}
                <select name="duration" id="durationSelect" onChange={(e) => setDuration(e.target.value)}>
                        <option value="">--Durée--</option>
                        <option value="29">moins de 30 min</option>
                        <option value="30">30 à 45 min</option>
                        <option value="45">45 à 60 min</option>
                        <option value="61">plus de 60 min</option>
                </select>
                {/*----- End of Select the duration -----*/}

                {/*----- Select the number of players -----*/}
                <p>Nombre de joueurs</p>
                <ul id="nbPlayers">
                    <li onClick={() => filterPlayers(1)}>exactement 2</li>
                    <li onClick={() => filterPlayers(2)}>2</li>
                    <li onClick={() => filterPlayers(3)}>3</li>
                    <li onClick={() => filterPlayers(4)}>4</li>
                    <li onClick={() => filterPlayers(5)}>5</li>
                    <li onClick={() => filterPlayers(6)}>6</li>
                    <li onClick={() => filterPlayers(7)}>7</li>
                    <li onClick={() => filterPlayers(8)}>8</li>
                    <li onClick={() => filterPlayers(9)}>+ de 8</li>
                </ul>
                {/*----- End Select the number of players -----*/}

                {/*----- Select the difficulty -----*/}
                <select name="difficulty" id="difficultySelect" onChange={(e) => setDifficulty(e.target.value)}>
                        <option value="">--Difficulté--</option>
                        <option value="facile">Facile</option>
                        <option value="moyen">Moyen</option>
                        <option value="difficile">Difficile</option>
                </select>
                {/*----- End Select the difficulty -----*/}

                {/*----- Input used to search through the boardgame database -----*/}
                <InputContainer>
                    <input name="searchInput" id="searchInput" type="text" placeholder="Rechercher..." onChange={(e) => {setSearchTerm(e.target.value)}} />
                    <label htmlFor="searchInput"><FontAwesomeIcon icon={faSearch} /></label>
                </InputContainer>
                {/*----- End of Input -----*/}
                </FiltersContainer>
                <Grid breakpointCols={breakpointsColumnsObj} columnClassName="my-masonry-grid_column">
                    {/* Filter the game with the search bar, the difficulty select, the duration select and the number of players. Finally map all the games according to filters*/}
                    {data.filter((game) => {
                        if (searchTerm === '') {
                            return game;
                        } else if (game.attributes.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                            return game;
                        } return false;
                    }).filter((game) => {
                        if (game.attributes.difficulty === difficulty) {
                            return game;
                        } else if (difficulty === '') {
                            return game;
                        } return false;
                    }).filter((game) => {
                        if (players === '') {
                            return game;
                        } else if (players === 1) {
                            if (players === game.attributes.playerMin - 1 && players === game.attributes.playerMax - 1) {
                                return game;
                            } return false;
                        } else if (players >= game.attributes.playerMin && players <= game.attributes.playerMax) {
                            return game;
                        } return false;
                    }).filter((game) => {
                        switch (duration) {
                            case '':
                                return game;
                            case '29':
                                if(game.attributes.durationMax < 30) {
                                    return game;
                                }
                                break;
                            case '30':
                                if(game.attributes.durationMin >= 30 && game.attributes.durationMax <= 45) {
                                    return game;
                                }
                                break;
                            case '45':
                                if(game.attributes.durationMin >= 45 && game.attributes.durationMax <= 60) {
                                    return game;
                                }
                                break;
                            case '61':
                                if(game.attributes.durationMax > 60) {
                                    return game;
                                }
                                break;
                            default:
                                break;
                        } return false;
                    }).map((game) => (
                        <div className="divId" key={game.id}>
                            <Card 
                                name = {game.attributes.name}
                                description = {game.attributes.description}
                                difficulty = {game.attributes.difficulty}
                                playerMin = {game.attributes.playerMin}
                                playerMax = {game.attributes.playerMax}
                                durationMin = {game.attributes.durationMin}
                                durationMax = {game.attributes.durationMax}
                                age = {game.attributes.age}
                                isCoop = {game.attributes.isCoop}
                                picture = {game.attributes.picture.data.attributes.url}
                            />
                        </div>
                    ))}
                </Grid>
            </PageContentContainer>
        </GamesContainer>
    )
}

export default Games
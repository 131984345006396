import styled from "styled-components";
import { Link } from "react-router-dom";
import colors from "../../utils/style/colors";

const FooterContainer = styled.div`
  width: 100%;
  background: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 150px;
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 200;
  margin-left: 10px;
  margin-top: 10px;
  font-size: 0.8rem;
  color: #fff;
  transition: color ease-in-out 300ms;
  &:hover {
    color: ${colors.primary};
  }
`;

const Copyright = styled.p`
  color: #fff;
  font-weight: 300;
  font-size: 0.8rem;
  margin-top: 30px;
`;

function Footer() {
  return (
    <FooterContainer>
      <LinksContainer>
        <StyledLink to="/">
          Les Arpenteurs, bar, mystères et jeux de société
        </StyledLink>
        <StyledLink to="/">Nos jeux</StyledLink>
        <StyledLink to="/">Notre carte</StyledLink>
      </LinksContainer>
      <Copyright>
        Made with ❤ by{" "}
        <a href="https://julien.gg" style={{ color: "#fff" }}>
          Julien Grangé-Guermente
        </a>
      </Copyright>
    </FooterContainer>
  );
}

export default Footer;

import { faLeaf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const ProductContainer = styled.div`
    display: flex;
    flex-direction: column;
    @media (min-width: 900px) {
        margin: 0 2.5%;
        flex: 1 1 45%;
        box-sizing: border-box;
    }   
`

const ProductHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid grey;
    padding-bottom: 5px;
    box-sizing: border-box;
    h1 {
        font-weight: 500;
        font-size: 1.25rem;
        max-width: 80%;
    }
`

const ProductDescription = styled.p`
    color: #555;
    font-weight: 300;
    padding-top: 5px;
    margin-bottom: 20px;
`

const Icon = styled(FontAwesomeIcon)`
    border-radius: 50%;
    padding: 5px;
    font-size: 0.75rem;
    background-color: green;
`

function Product({ name, description, price, isBio, productCategory }) {
    const bio = isBio ? (<Icon icon={faLeaf} color="#fff"/>) : '';

    return(
        <ProductContainer>
            <ProductHeader>
                <h1>{name} {bio}</h1>
                <h1>{price} €</h1>
            </ProductHeader>
            <ProductDescription>{description}</ProductDescription>
        </ProductContainer>
    )
}

export default Product
import { createGlobalStyle } from "styled-components";
import BackgroundImg from "../../assets/fond-site-web-scaled.jpg";

const StyledGlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        list-style: none;
        font-family: Lato, console-sans;
        scroll-behavior: smooth;
    }
    body {
        padding-top: 75px;
        background: url(${BackgroundImg});
        background-position: top;
        background-size: contain;
    }
`

function GlobalStyle() {
    return <StyledGlobalStyle />
}

export default GlobalStyle
import styled from 'styled-components'
import ErrorIllustration from '../../assets/404.svg'

const ErrorWrapper = styled.div`
  margin: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  padding: 50px;
  box-sizing: border-box;
`

const ErrorTitle = styled.h1`
  font-weight: 300;
`

const ErrorSubtitle = styled.h2`
  font-weight: 300;
  text-align: center;
`

const Illustration = styled.img`
  max-width: 350px;
  margin: 25px 0;
`

function Error() {

  return (
    <ErrorWrapper>
      <ErrorTitle>Oups...</ErrorTitle>
      <Illustration src={ErrorIllustration}/>
      <ErrorSubtitle>
        Ce n'est pas la page web que tu recherches.
      </ErrorSubtitle>
    </ErrorWrapper>
  )
}

export default Error
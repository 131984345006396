import styled from "styled-components";
import BigLogo from "../../assets/logo-les-arpenteurs.png";
import Meeple from "../../assets/meeple.jpg";
import rhumImg from "../../assets/bouteilles-rhum.jpg";
import mystereImg from "../../assets/symboles-etranges.jpg";
import Tireuse from "../../assets/tireuse.jpg";
import colors from "../../utils/style/colors";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBeer,
  faClock,
  faDice,
  faMapMarkerAlt,
  faPuzzlePiece,
} from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { emailTemplate } from "../../constants/emailTemplate";

const HomeContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeroBanner = styled.div`
  width: 75%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  box-sizing: border-box;
`;

const HeroBannerLogo = styled.img`
  height: 50vh;
  display: flex;
`;

const Button = styled(Link)`
  background-color: ${colors.primary};
  text-align: center;
  text-decoration: none;
  padding: 1em 2em;
  color: #fff;
  border-radius: 30px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.8rem;
  margin: 50px auto 0 auto;
  display: flex;
  justify-content: center;
  width: fit-content;
`;

const MailToButton = styled.a`
  background-color: ${colors.primary};
  text-align: center;
  text-decoration: none;
  padding: 1em 2em;
  color: #fff;
  border-radius: 30px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.8rem;
  margin: 50px auto 0 auto;
  display: flex;
  justify-content: center;
  width: fit-content;
`;

const PageContentContainer = styled.div`
  width: 100%;
  background-color: #fff;
  @media (min-width: 768px) {
    border-radius: 10px;
    width: 95%;
  }
`;

const Section = styled.section`
  width: 100%;
  box-sizing: border-box;
  padding: 50px 20px;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
`;

const TitleH1 = styled.h1`
  font-size: 2rem;
  font-weight: 500;
  font-family: Lato;
  text-align: center;
  color: #3c4858;
  margin-bottom: 50px;
`;

const TitleH2 = styled.h2`
  font-size: 1.2rem;
  font-weight: 500;
  font-family: Lato;
  text-align: center;
  color: #3c4858;
  margin-bottom: 20px;
`;

const Paragraph = styled.p`
  font-family: Lato;
  font-weight: 300;
  font-size: 18px;
  color: ${colors.paragraph};
  text-align: center;
  margin-bottom: 20px;
  line-height: 1.5rem;
  span {
    font-weight: 600;
  }
  @media (min-width: 768px) {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
`;

const BigIcon = styled(FontAwesomeIcon)`
  display: flex;
  font-size: 4rem;
  margin: 75px auto 30px auto;
  transition: all ease-in-out 200ms;
  &:hover {
    transform: scale(1.1);
    filter: opacity(0.75);
  }
`;
const SocialContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const SocialIcon = styled(FontAwesomeIcon)`
  display: flex;
  font-size: 2.5rem;
  margin: 0 20px;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  @media (min-width: 1080px) {
    flex-direction: row;
    align-items: stretch;
  }
`;

const Card = styled.div`
  width: 90%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  max-width: 350px;
  @media (min-width: 1080px) {
    max-width: 450px;
    flex-direction: row;
    margin: 0 20px;
  }
`;

const CardImg = styled.img`
  border-radius: 10px 10px 0 0;
  max-width: 450px;
  @media (min-width: 1080px) {
    max-width: 40%;
    border-radius: 10px 0 0 10px;
  }
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
`;

const CardTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: 500;
  font-family: Lato;
`;

const CardSubtitle = styled.h4`
  font-size: 0.8rem;
  font-weight: 300;
  font-family: Lato;
  text-transform: uppercase;
  margin-top: 10px;
`;

const CardText = styled.p`
  margin-top: 10px;
  font-size: 1rem;
  font-weight: 200;
  color: ${colors.paragraph};
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 20px;
`;

const SpecialSection = styled(Section)`
  background-color: #222;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5%;
  flex-direction: row;

  @media (max-width: 1080px) {
    flex-direction: column;
  }
`;

function Home() {
  return (
    <HomeContainer>
      <HeroBanner>
        <HeroBannerLogo src={BigLogo} />
        <Button to="/nos-jeux">
          Découvre notre outil de sélection de jeux de société !
        </Button>
      </HeroBanner>
      <PageContentContainer>
        <Section>
          <TitleH1>Bar, mystères et jeux de société</TitleH1>
          <Paragraph>
            Les Arpenteurs est un bar à bières avec des jeux de société et des
            énigmes en plein cœur de Lyon, à deux pas de la place des Terreaux.
          </Paragraph>
          <Paragraph>
            ​Venez goûter nos bières artisanales et locales à la pression, nos
            vins bio sélectionnés par nos soins et nos planches garnies de
            produits locaux. Des pâtisseries faites maison vous sont proposées
            les week-end pour combler toutes les fringales, accompagnées de jus
            de fruits, thés et tisanes du coin !
          </Paragraph>
          <BigIcon icon={faBeer} color={colors.primary} />
          <TitleH2>Boissons locales et artisanales</TitleH2>
          <Paragraph>
            ​Toutes nos bières et nos boissons sans alcool sont artisanales et
            locales. Nous les sélectionnons avec soin pour ravir tes papilles et
            te faire découvrir des petites pépites souvent méconnues !
          </Paragraph>
          <BigIcon icon={faDice} color="#d1007d" />
          <TitleH2>Jeux de société</TitleH2>
          <Paragraph>
            ​Une sélection de près de 200 jeux de société pour s'amuser toute la
            soirée ! Jeux d'ambiance, de stratégie, d'apéro, tu trouveras
            certainement ton bonheur !
          </Paragraph>
          <BigIcon icon={faPuzzlePiece} color="#4caf50" />
          <TitleH2>Des énigmes</TitleH2>
          <Paragraph>
            Et si malgré tout tu as peur de t'ennuyer, tu peux tenter de percer
            les secrets du bar en résolvant les nombreuses énigmes dissimulées
            un peu partout !
          </Paragraph>
        </Section>
        <Section style={{ backgroundImage: `url(${Meeple})` }}>
          <TitleH1 style={{ color: "#fff" }}>
            Trouve ton jeux grâce à notre outil en ligne
          </TitleH1>
          <Paragraph style={{ color: "#fff" }}>
            Ça y est, tu as une bière, à manger, tu es installé bien au chaud ?
            Tu es prêt à passer toute la soirée à t’éclater sur un super jeu ?
            Seul problème … <span>tu ne sais pas quoi choisir !</span>
          </Paragraph>
          <Paragraph style={{ color: "#fff" }}>
            Pas de problème ! Grâce à notre{" "}
            <span>outil de sélection de jeux en ligne</span>, tu vas directement
            pouvoir consulter le <span>catalogue de jeux</span> que nous avons
            au bar et <span>filtrer selon tes envies</span>. Plutôt jeux à deux
            ? En coopération ? Stratégique ? Ou pour des parties rapides ?
          </Paragraph>
          <Button to="/nos-jeux">
            Vite, je veux avoir des conseils de jeux !
          </Button>
        </Section>
        <Section>
          <TitleH1>Les petits trucs en plus</TitleH1>
          <Paragraph>
            Parce que Les Arpenteurs, c'est un petit peu plus qu'un simple bar !
            <br />
            <br />
            Suivez-nous sur Facebook et Instagram pour ne rien manquer de nos
            événements !
          </Paragraph>
          <SocialContainer>
            <a
              href="https://www.facebook.com/lesarpenteursbar"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SocialIcon icon={faFacebook} color="#1b74e4" />
            </a>
            <a
              href="https://www.instagram.com/lesarpenteurslyon/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SocialIcon icon={faInstagram} color="#b72ead" />
            </a>
          </SocialContainer>
          <CardContainer>
            <Card>
              <CardImg src={rhumImg}></CardImg>
              <CardBody>
                <CardTitle>Des soirées et des jeux</CardTitle>
                <CardSubtitle>Événements</CardSubtitle>
                <CardText>
                  Des soirées à thèmes, des tournois de jeux de société, des
                  tests de prototypes sont régulièrement organisés. Rendez-vous
                  sur notre page Facebook pour en savoir plus !
                </CardText>
              </CardBody>
            </Card>
            <Card>
              <CardImg src={mystereImg}></CardImg>
              <CardBody>
                <CardTitle>Un bar à énigmes ?</CardTitle>
                <CardSubtitle>Mystères</CardSubtitle>
                <CardText>
                  Des énigmes et devinettes sont dissimulées dans le bar, sur
                  notre site internet et … ailleurs.
                  <br />
                  <br />
                  Résolvez ces mystères afin de gagner des récompenses.
                  <br />
                  <br />
                  Faites attention de ne pas perdre la raison, certains mystères
                  sont faits pour rester cachés… Et d’autres pour être révélés !
                </CardText>
              </CardBody>
            </Card>
          </CardContainer>
        </Section>
        <SpecialSection id="reservation">
          <div style={{ flex: 0.5 }}>
            <TitleH1 style={{ color: "#fff" }}>
              Réserver un bar à Lyon pour une soirée d'entreprise.
            </TitleH1>
            <Paragraph style={{ color: "#fff", width: "100%" }}>
              Vous souhaitez organiser une soirée dans un bar entièrement
              privatisé pour une occasion bien spécifique ? Un team building ?
              Un colloque annuel ? Une réunion d'anciens étudiants ? Privatisez
              notre établissement uniquement pour vous, jusqu'à 65 personnes.
            </Paragraph>
            <TitleH2 style={{ color: "#fff" }}>
              Contactez-nous directement{" "}
              <a
                href={`mailto:contact@lesarpenteurs-bar.com?subject=Demande de réservation&body=${emailTemplate}`}
                target="_blank"
                rel="noreferrer"
                style={{ color: "#fff" }}
                onClick={() => {
                  window.gtag("event", "conversion", {
                    send_to: "AW-759456826/4X4zCNHozawZELrIkeoC",
                    cta: "home/send-mail-text-link",
                  });
                }}
              >
                par mail
              </a>{" "}
              ou en allant sur{" "}
              <a
                href="https://www.reserverunbar.fr/lieu/8330/les-arpenteurs"
                target="_blank"
                rel="noreferrer"
                style={{ color: "#fff" }}
                onClick={() => {
                  window.gtag("event", "conversion", {
                    send_to: "AW-759456826/4X4zCNHozawZELrIkeoC",
                    cta: "home/reserverunbar-text-link",
                  });
                }}
              >
                notre fiche sur le site Reserverunbar.fr
              </a>{" "}
              pour établir un devis sur-mesure qui conviendra à vos besoins.
            </TitleH2>
            <MailToButton
              href={`mailto:contact@lesarpenteurs-bar.com?subject=Demande de réservation&body=${emailTemplate}`}
              onClick={() => {
                window.gtag("event", "conversion", {
                  send_to: "AW-759456826/OI25CNTozawZELrIkeoC",
                  cta: "home/button",
                });
              }}
            >
              Demander un devis par mail
            </MailToButton>
          </div>
        </SpecialSection>
        <Section
          style={{
            backgroundImage: `url(${Tireuse})`,
            backgroundPosition: "top",
          }}
          id="contact"
        >
          <TitleH1 style={{ color: "#fff" }}>Nous contacter</TitleH1>
          <Paragraph style={{ color: "#fff" }}>
            Le bar Les Arpenteurs est ouvert tous les jours. Pour toute
            question, vous pouvez nous contacter directement sur{" "}
            <a
              href="https://www.facebook.com/lesarpenteursbar/?locale=fr_FR"
              target="_blank"
              rel="noreferrer"
              style={{ color: "#fff" }}
            >
              Facebook
            </a>{" "}
            ou{" "}
            <a
              href="https://www.instagram.com/lesarpenteurslyon/"
              target="_blank"
              rel="noreferrer"
              style={{ color: "#fff" }}
            >
              Instagram
            </a>
            .
          </Paragraph>
          <InfoContainer>
            <Info>
              <a
                href="https://www.google.fr/maps/place/13+Rue+Lanterne,+69001+Lyon/"
                style={{ textDecoration: "none" }}
              >
                <BigIcon icon={faMapMarkerAlt} color="#fff" />
                <TitleH2 style={{ color: "#fff" }}>Où nous trouver</TitleH2>
                <Paragraph style={{ color: "#fff", width: "fit-content" }}>
                  13 rue Lanterne, 69001 Lyon
                </Paragraph>
              </a>
            </Info>
            <Info>
              <BigIcon icon={faClock} color="#fff" />
              <TitleH2 style={{ color: "#fff" }}>Nos horaires</TitleH2>
              <Paragraph style={{ color: "#fff", width: "fit-content" }}>
                Lundi : 17h00-22h30
                <br />
                <br />
                Mardi-Vendredi : 17h-00h30
                <br />
                <br />
                Samedi : 15h-00h30
                <br />
                <br />
                Dimanche : 15h-22h30
              </Paragraph>
            </Info>
          </InfoContainer>
        </Section>
      </PageContentContainer>
    </HomeContainer>
  );
}

export default Home;
